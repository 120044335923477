<template>
  <sub-page
      :title="$t('WebSite')"
      icon="mdi-web"
      v-model="$store.state.settings.pageData"
  >
    <ws-accordion
        class="mt-5"
        :items="navigationItems"
    >
      <template #action.gAnalytics>
        <ws-business-service-key-select
            v-model="serviceKeys.gTag"
            @input="$store.state.business.selectedBusiness.gTag = $event"
            service-key="gTag"
            :title="$t('LinkGoogleAnalytics')"
            label="Google Analytics Tag"
            placeholder="Google Tag"
            class="mr-5"
        />
      </template>

      <template #action.facebook_code>
        <ws-business-service-key-select
            v-model="serviceKeys.fPixelId"
            @input="$store.state.business.selectedBusiness.fPixelId = $event"
            service-key="fPixelId"
            :title="$t('LinkFacebookPixel')"
            label="Facebook Pixel"
            placeholder="Facebook Pixel"
            class="mr-5"
        />
      </template>

    </ws-accordion>
  </sub-page>
</template>

<script>
import wsBusinessServiceKeySelect
  from "@/components/pages/businessDashboard/businessSettings/UI/wsBusinessServiceKeySelect";
export default {
  name: "settingsSite",
  components : {
    wsBusinessServiceKeySelect
  },
  data() {
    return {
      serviceKeys : {}
    }
  },
  computed : {
    navigationItems() {
      return [
        { name : 'Google Analytics' ,
          subtitle : this.$t('GoogleAnalyticsDescription'),
          value : 'gAnalytics' ,
          icon : 'mdi-google'
        },
        { name : 'Facebook Pixel: Tag Id' ,
          subtitle : this.$t('FacebookPixelDescription'),
          value : 'facebook_code' ,
          icon : 'mdi-facebook'
        }
      ]
    }
  },
  methods : {
    initPage() {
      this.serviceKeys.gTag = !!this.$store.state.business.selectedBusiness.gTag
      this.serviceKeys.fPixelId = !!this.$store.state.business.selectedBusiness.fPixelId
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>